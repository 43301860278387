import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { HeroesList } from "@/game-deadlock/components/HeroPerformance.style.jsx";
import type { ProfileHero } from "@/game-deadlock/models/profileHeroStats.mjs";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import { heroWinrateColor } from "@/game-deadlock/utils/hero-winrate-color.mjs";
import { kdaColor } from "@/game-deadlock/utils/kda-color.mjs";
import { useHeroesList } from "@/game-deadlock/utils/use-heroes-list.mjs";
import BlitzLogoLoading from "@/shared/BlitzLogoLoading.jsx";
import ListItemsTruncated from "@/shared/ListItemsTruncated.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function HeroPerformance({ steamId }: { steamId: string }) {
  const { t } = useTranslation();

  const {
    deadlock: { profileHeroStats },
  } = useSnapshot(readState);

  const { loading: _heroesListLoading, dict: heroesDict } = useHeroesList();

  const stats = profileHeroStats[steamId];
  const isLoading = !stats;

  if (isLoading) {
    return (
      <div className="relative">
        <Card loading style={{ height: 420 }} />
        <div className="absolute-center">
          <BlitzLogoLoading />
        </div>
      </div>
    );
  }

  const statsList = Object.entries(stats)
    .map(([heroId, stats]: [string, ProfileHero]) => {
      return {
        heroId: Number(heroId),
        games: stats.games_played,
        winrate: stats.wins / stats.games_played,
        kda: (stats.kills + stats.assists) / (stats.deaths || 1),
      };
    })
    .sort((a, b) => b.games - a.games) as {
    heroId: number;
    games: number;
    winrate: number;
    kda: number;
  }[];

  return (
    <div className="relative">
      <ListItemsTruncated
        className={HeroesList()}
        title={t("deadlock:stats.heroStats", "Hero Stats")}
        list={statsList}
        headerControls={null}
        onShowMore={() => {}}
        itemLeftContent={(hero) => {
          return (
            <>
              <img
                src={Assets.getHeroPortrait(hero.heroId)}
                width="36px"
                height="49px"
              />
              <div className="meta">
                <span className="hero-name type-subtitle--bold">
                  {heroesDict[hero.heroId]?.heroName || "--"}
                </span>
                <span
                  className="type-caption--semi"
                  style={{ color: kdaColor(hero.kda) }}
                >
                  {t("lol:matchTile.kda", "{{kda}} KDA", {
                    kda: hero.kda.toLocaleString(getLocale(), {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }),
                  })}
                </span>
              </div>
            </>
          );
        }}
        itemRightContent={(hero) => {
          return (
            <>
              <span className="type-caption--semi shade1">
                {t("common:stats.matchesCount", "{{count, number}} Matches", {
                  count: hero.games,
                })}
              </span>
              <span
                className="type-caption--semi"
                style={{ color: heroWinrateColor(hero.winrate) }}
              >
                {t("common:percentWinrate", "{{winRate, percent}} Win Rate", {
                  winRate: hero.winrate,
                })}
              </span>
            </>
          );
        }}
        itemLinkFormat={(hero) => {
          const staticHero = heroesDict[hero.heroId];
          return staticHero
            ? `/deadlock/heroes/${staticHero.heroName}`
            : `/deadlock/heroes/${hero.heroId}`;
        }}
      />
    </div>
  );
}

export default memo(HeroPerformance);
